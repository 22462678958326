* {
  font-family: Montserrat, sans-serif;
}

/*  ========= mul ti pol from ==============  */
.bg-dark-purple {
  background-color: #a663cc;
}

.text-light-purple {
  color: rgb(132 79 250);
}

.bg-gray-3 {
  background-color: rgb(172 181 189);
}

.text-gray-3 {
  color: black;
}

.bg-background-gray {
  background-color: rgb(246 246 246);
}

.border-gray-3 {
  border-color: rgb(146, 146, 146);
}

.bg-white {
  background-color: rgb(255 255 255);
}

.bg-light-purple {
  background-color: rgb(132 79 250);
}

.hover\:bg-dark-purple:hover {
  background-color: #a663cc;
}

/*  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
*/
